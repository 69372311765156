import { joinPathToEnvBase, joinUrl } from "./joinUrl";
import { track } from "./track";

type OperationVariables = Record<string, any>;
type DefaultData = {
  success: boolean;
  message?: string;
} & OperationVariables;

export interface MutationResultPositive<TData extends any> {
  data: TData;
  error: undefined;
  loading: false;
  called: true;
}

export interface MutationResultNegative {
  data?: undefined;
  error?: any;
  loading: boolean;
  called: boolean;
}

export type MutationResult<TData extends any> =
  | MutationResultPositive<TData>
  | MutationResultNegative;

interface ExecutionResultPositive<TData extends any> {
  data: TData;
  error: undefined;
}

interface ExecutionResultNegative {
  data: undefined;
  error: any;
}

export type ExecutionResult<TData extends any> =
  | ExecutionResultPositive<TData & DefaultData>
  | ExecutionResultNegative;

export interface QueryOptions<TVariables extends OperationVariables> {
  headers?: HeadersInit;
  variables?: TVariables | {};
  withoutLoading?: boolean;
  stopIfNoToken?: boolean;
}

export const fetchBase = <TData extends any, TVariables = any>(
  queryString: string,
  //@ts-ignore
  options: QueryOptions<TVariables> = {},
): Promise<ExecutionResult<TData>> => {
  const {
    headers = {},
    variables = {},
    withoutLoading,
    stopIfNoToken,
  } = options;

  const fetchData = async () => {
    let data;
    let currentError;

    try {
      const queryHeaders = {
        "Content-Type": "application/x-www-form-urlencoded",
        ...headers,
      };

      const token = window.localStorage.getItem("token");
      const language = window.localStorage.getItem("locale") || "en";

      if (stopIfNoToken && !token) {
        return {
          data: undefined,
          error: undefined,
        };
      }

      const queryBody = JSON.stringify({
        token,
        language,
        ...variables,
      });

      let query = joinPathToEnvBase(queryString);

      if (/http/g.test(queryString)) {
        query = queryString;
      }

      const response = await fetch(query, {
        method: "POST",
        headers: queryHeaders,
        body: queryBody,
        cache: "no-cache",
      });

      data = await response.json();
    } catch (err) {
      track()?.errors(err, "fetchBase", {
        custom: {
          queryString,
          options: JSON.stringify(options),
        },
      });

      currentError = err;
    }

    if (!withoutLoading) {
      if (data?.token_refreshed && data?.new_token) {
        window.localStorage.setItem("token", data.new_token);
      }

      if (data && data.success) {
      } else if (data?.bad_token) {
        window.localStorage.setItem("token", "");
      } else if (data && data["2fa_required"]) {
        window.localStorage.setItem("tfa", "true");
      }
    }

    if (!data?.success) {
      currentError = data?.message || "Oops, unrecognized error has occurred.";
    }

    return {
      data,
      error: currentError,
    };
  };

  return fetchData();
};
