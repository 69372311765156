function normalizeSlashes(url: string) {
  return url.replace(/\/{2,}/g, "/");
}
const replaceSlash = (baseUrl: string, path: string) => {
  path = normalizeSlashes(path);
  return baseUrl.replace(/\/+$/, "") + "/" + path.replace(/^\/+/, "");
};

export const joinUrl = (baseUrl: string, path: string) => {
  path = normalizeSlashes(path);

  let url = baseUrl + "/" + path;
  try {
    url = new URL(path, baseUrl).href;
  } catch (error) {
    url = replaceSlash(baseUrl, path);
  }
  return url;
};

export const joinPathToEnvBase = (path: string) => {
  const base = process.env.REACT_APP_URL as string;
  return joinUrl(base, path);
};
